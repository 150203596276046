<template>
    <div
      style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
    >
      <div style="display: flex; flex-wrap: wrap">
        <div class="condition-item" v-if="searchButton">
          <label class="fn-14">学校</label>
          <el-select
            clearable
            filterable
            v-model="searchData.schoolId"
            remote
            reserve-keyword
            placeholder="请输入学校名或代码检索"
            :remote-method="remoteMethod"
            :loading="loading"
            autocomplete="off"
            size="small"
          >
            <el-option
              v-for="item in schools"
              :key="item.id"
              :label="item.schoolFilterName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="condition-item">
          <label class="fn-14">院校代码</label>
          <el-input
            clearable
            v-model="searchData.schoolCode"
            placeholder="请输入院校代码"
            autocomplete="off"
            size="small"
            style="width: 130px"
          >
          </el-input>
        </div>
  
        <div class="condition-item" v-if="searchButton">
          <label class="fn-14">身份证号</label>
          <el-input
            clearable
            style="width: 180px"
            v-model="searchData.studentIdNumber"
            placeholder="身份证号"
            autocomplete="off"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
          <label class="fn-14">学号</label>
          <el-input
            clearable
            style="width: 130px"
            v-model="searchData.studentCode"
            placeholder="请输入学号"
            autocomplete="off"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
          <label class="fn-14">采集码</label>
          <el-input
          clearable
            style="width: 130px"
            v-model="searchData.cjm"
            placeholder="请输入采集码"
            autocomplete="off"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
        <label class="fn-14">订单号</label>
        <el-input
        clearable
          style="width: 130px"
          v-model="searchData.orderNo"
          placeholder="请输入订单号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
        <div class="condition-item">
          <el-button
            v-if="searchButton"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            >查询
          </el-button>
        </div>
      </div>
  
      <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
        <el-table
          :data="orderList"
          v-loading="tableLoading"
          stripe
          border
          height="100%"
          :row-style="changeRowBgColorByIsPay"
        >
          <template #empty>
            <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
          </template>
          <el-table-column
            prop="orderNo"
            label="订单号"
            width="240"
          ></el-table-column>
          <el-table-column
            prop="studentName"
            label="姓名"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                execDecrypt(scope.row.studentName, "name")
              }}</span></template
            >
          </el-table-column>
          <el-table-column
            prop="studentCode"
            label="学号"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="studentIdNumber"
            label="身份证号"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                execDecrypt(scope.row.studentIdNumber, "Idcard")
              }}</span></template
            >
          </el-table-column>
          <el-table-column
            prop="collectedTime"
            :formatter="columnDateFormat"
            label="采集时间"
            width="200"
            align="center"
          >
          </el-table-column>
  
          <el-table-column
            prop="schoolCode"
            label="院校代码"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="院校名称"
            width="240"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="classes" label="班级" width="200" align="center">
          </el-table-column>
          <el-table-column
            prop="branchCollege"
            label="分院"
            width="160"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="shotPrice"
            label="金额"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="pathCollectedPhoto"
            label="采集状态"
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.pathCollectedPhoto == "" ? "未采集" : "已采集" }}
            </template>
          </el-table-column>
  
          <el-table-column fixed="right" label="操作" width="260" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="viewButton"
                type="text"
                @click="handleView(scope.row.id)"
                >查看
              </el-button>
              <el-button
                v-if="uploadButton"
                type="text"
                v-show="scope.row.pathCollectedPhoto == ''"
                @click="handleImport(scope.row.id)"
                >上传照片
              </el-button>
              <el-button
                v-if="reUploadButton"
                v-show="scope.row.pathCollectedPhoto !== ''"
                type="text"
                @click="handleReImport(scope.row.id, scope.row)"
                >重传照片
              </el-button>
  
              <el-button
                v-if="showOrderLogXp"
                type="text"
                @click="showOrderLog(scope.row.id)"
              >
                订单日志
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
  
      <div style="display: flex; justify-content: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog
        title="订单信息"
        :visible.sync="dialogVisible"
        width="1000px"
        append-to-body
      >
        <div style="max-height: 600px; overflow: hidden; overflow-y: auto">
          <el-form ref="orderForm" :model="orderForm" label-width="80px">
            <div class="tip">订单信息</div>
            <el-row class="rowGray">
              <el-col class="cell" :span="4">
                <label>订单号</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="orderForm.orderNo||'-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>采集状态</label>
              </el-col>
              <el-col class="cell" :span="8">
                <span v-show="orderForm.pathCollectedPhoto == ''">未采集</span>
                <span v-show="orderForm.pathCollectedPhoto !== ''">已采集</span>
              </el-col>
            </el-row>
  
            <el-row class="rowBlank">
              <el-col class="cell" :span="4">
                <label>支付金额</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="orderForm.shotPrice||'-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>采集时间</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label>{{ formDateTimeFormat(orderForm.uploadTime)||'-' }}</label>
              </el-col>
            </el-row>
          </el-form>
  
          <el-form
            ref="orderForm"
            :model="orderForm"
            label-width="80px"
            style="margin-top: 15px"
          >
            <div class="tip">学籍信息</div>
            <div class="">
              <div class="">
                <el-row class="">
                  <el-col :span="20">
                    <el-row class="rowGray">
                      <el-col class="cell" :span="4">
                        <label>姓名</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.studentName||'-'"></label>
                      </el-col>
                      <el-col class="cell" :span="4">
                        <label>身份证号</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.studentIdNumber||'-'"></label>
                      </el-col>
                    </el-row>
                    <el-row class="rowBlank">
                      <el-col class="cell" :span="4">
                        <label>学校名称</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.schoolName||'-'"></label>
                      </el-col>
                      <el-col class="cell" :span="4">
                        <label>院校代码</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.schoolCode||'-'"></label>
                      </el-col>
                    </el-row>
                    <el-row class="rowGray">
                      <el-col class="cell" :span="4">
                        <label>学校类别</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.schoolType||'-'"></label>
                      </el-col>
                      <el-col class="cell" :span="4">
                        <label>学历类别</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.educationType||'-'"></label>
                      </el-col>
                    </el-row>
                    <el-row class="rowBlank">
                      <el-col class="cell" :span="4">
                        <label>班级</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.classes||'-'"></label>
                      </el-col>
                      <el-col class="cell" :span="4">
                        <label>分院</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.branchCollege||'-'"></label>
                      </el-col>
                    </el-row>
                    <el-row class="rowGray">
                      <el-col class="cell" :span="4">
                        <label>专业</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.major||'-'"></label>
                      </el-col>
                      <el-col class="cell" :span="4">
                        <label>学号</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.studentCode||'-'"></label>
                      </el-col>
                    </el-row>
                    <el-row class="rowBlank">
                      <el-col class="cell" :span="4">
                        <label>考生号</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label v-text="orderForm.studentExamineeNumber||'-'"></label>
                      </el-col>
                      <el-col class="cell" :span="4">
                        <label>毕业时间</label>
                      </el-col>
                      <el-col class="cell" :span="8">
                        <label>{{
                          formDateTimeFormat(orderForm.graduationTime)||'-'
                        }}</label>
                      </el-col>
                    </el-row>
                  </el-col>
  
                  <el-col :span="4" style="text-align: center">
                    <el-image :src="photoImg" class="photo">
                      <div slot="placeholder" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <div>
                      <el-link type="primary" v-if="isPhoto" @click="clickDown"
                        >照片下载</el-link
                      >
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="clear-float"></div>
            </div>
          </el-form>
          <el-form
            ref="orderForm"
            :model="orderForm"
            label-width="80px"
            style="margin-top: 15px"
          >
            <div class="tip">
              <span v-if="orderForm.status == 2">采集信息</span>
              <span v-else>认证信息</span>
            </div>
            <el-row class="rowGray">
              <el-col class="cell" :span="4">
                <label>采集类型</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="orderForm.shotTypeName||'-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>采集时间</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label>{{ formDateTimeFormat(orderForm.uploadTime)||'-' }}</label>
              </el-col>
            </el-row>
  
            <el-row class="rowBlank">
              <el-col class="cell" :span="4">
                <label>采集码</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="orderForm.cjm||'-'"></label>
              </el-col>
              <el-col class="cell" :span="4">
                <label>上传批次</label>
              </el-col>
              <el-col class="cell" :span="8">
                <label v-text="orderForm.batchName||'-'"></label>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="dialogVisible = false">返 回</el-button>
        </div>
      </el-dialog>
  
      <!--    上传学生照片-->
      <el-dialog
        title="上传学生照片"
        :visible.sync="dlgImportVisible"
        width="500px"
        center="center"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :rules="importRules"
          :model="fileForm"
          ref="fileForm"
          label-width="100px"
          @submit.native.prevent
        >
          <el-form-item
            label="重传原因："
            prop="mode"
            v-show="uploadImgType == 2"
          >
            <el-radio-group v-model="fileForm.type">
              <el-radio :label="1">图片处理</el-radio>
              <el-radio :label="2">增值服务</el-radio>
              <el-radio :label="3">勘误</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传照片：" prop="file" style="margin-bottom: 0">
            <el-row class="photo-box">
              <el-col
                :span="9"
                style="margin-right: 20px"
                v-show="uploadImgType == 2"
              >
                <div class="item">
                  <el-image :src="photoImg" class="photo">
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <div class="desc">原照片</div>
                </div>
              </el-col>
              <el-col :span="9">
                <div class="item">
                  <el-upload
                    class="upload-demo"
                    ref="uploadImport"
                    :multiple="false"
                    action=""
                    :limit="1"
                    :show-file-list="false"
                    :on-change="handleChange"
                    :file-list="fileList"
                    accept="image/jpeg,image/png"
                    list-type="picture"
                    :auto-upload="false"
                  >
                    <el-image :src="uploadImg" class="photo">
                      <div slot="placeholder" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </el-upload>
                  <div class="desc">
                    {{ uploadImgType == 2 ? "重传后的照片" : "上传照片" }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          style="margin: 0px 0 10px 0; text-align: center"
        >
          <el-button @click="dlgImportVisible = false">关 闭</el-button>
          <el-button type="primary" :loading="uploadLoading" @click="submitUpload"
            >确认更新</el-button
          >
        </div>
      </el-dialog>
  
      <!-- 订单日志 -->
      <el-dialog
        title="订单日志信息"
        :visible.sync="showOrderView"
        width="1000px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-table
          :data="orderLogsList"
          stripe
          border
          height="400"
          style="width: 100%"
        >
          <template #empty>
            <p>{{ orderLogsLoading == true ? "数据加载中" : "暂无数据" }}</p>
          </template>
  
          <el-table-column
            prop="username"
            label="操作人"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="状态"
            width="140"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="执行时间"
            :formatter="columnDateFormat"
            width="180"
            align="center"
          ></el-table-column>
          <el-table-column prop="note" label="日志"> </el-table-column>
        </el-table>
      </el-dialog>
  
      <el-dialog
        title="订单支付日志列表"
        :visible.sync="orderLogsVisible"
        width="900px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-table
          :data="OrderFlowList"
          stripe
          border
          height="400"
          style="width: 100%"
        >
          <el-table-column prop="orderNo" label="订单号"></el-table-column>
          <el-table-column prop="payId" label="工行订单号"></el-table-column>
          <el-table-column prop="status" label="订单状态">
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.status === 0"
                type="warning"
                effect="plain"
                size="small"
                >下单</el-tag
              >
              <el-tag
                v-else-if="scope.row.status === 1"
                type="success"
                effect="plain"
                size="small"
                >预付款
              </el-tag>
              <el-tag
                v-else-if="scope.row.status === 2"
                type=""
                effect="plain"
                size="small"
                >完成</el-tag
              >
              <el-tag v-else type="danger" effect="plain" size="small"
                >取消</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="isSuccess" label="支付状态">
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.isSuccess === 1"
                type="success"
                effect="plain"
                size="small"
                >成功</el-tag
              >
              <el-tag v-else type="danger" effect="plain" size="small"
                >失败</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="isSuccess" label="支付类型">
            <template slot-scope="scope">
              <span v-if="scope.row.payType === 0">线下支付</span>
              <span v-if="scope.row.payType === 1">E支付</span>
              <span v-if="scope.row.payType === 2">备用支付</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="workDatetime"
            label="执行时间"
            :formatter="columnDateFormat"
            width="160"
          ></el-table-column>
          <el-table-column prop="log" label="日志"> </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { getSchoolFiller } from "@/api/school";
  import { dateFormat } from "@/utils/date";
  import {
    queryOrders,
    getLogs,
    collectOrderInfo,
    uploadPhoto,
    reUploadPhoto,
  } from "@/api/encryption";
  import { queryPageButton } from "@/api/permission";
  import { getToken } from "@/utils/auth";
  import axios from "axios";
  import { formatPh, formatName, formatIdCard } from "@/utils/validate";
  export default {
    name: "onlineCollection",
    data() {
      return {
        // 列表搜索
        searchData: {
          schoolCode: "",
          schoolId: null,
          studentIdNumber: "",
          studentCode: "",
          shotTypeCode: "2",
          cjm: "",
          orderNo: null,
        },
  
        orderList: [],
        tableLoading: false,
        currentPage: 1,
        pageSize: 20,
        total: 0,
  
        // 订单日志
        OrderFlowList: [],
        orderLogsVisible: false,
        orderFlowByorderNo: false,
        showOrderLogXp: false,
        dialogType: 1,
        dialogVisible: false,
        // 查看数据
        orderForm: {},
        isPhoto: false,
        photoImg: "../temp/none_img.jpg",
        uploadImg: "../temp/none_img.jpg",
        uploadImgType: 1,
        status: null,
  
        //学校
        schools: [],
        value: [],
        allSchools: [],
        loading: false,
  
        //批量导入
        orderId: "",
        dlgImportVisible: false,
        uploadLoading: false,
        isReImport: false,
        fileForm: {
          type: 1,
          file: null,
        },
        hasFile: false,
        fileList: [],
        importRules: {
          file: [{ required: true, message: "请选择上传文件", trigger: "blur" }],
        },
  
        //按钮权限
        searchButton: false,
        viewButton: false,
        uploadButton: false,
        cencelButton: false,
        reUploadButton: false,
  
        // 订单日志
        showOrderView: false,
        orderLogsLoading: false,
        orderLogsList: [],
      };
    },
    created() {
      this.queryButton();
      this.query();
      this.querySchools();
    },
    methods: {
      changeRowBgColorByIsPay({ row, rowIndex }) {
        if (row.status === 1) {
          return { color: "#0bc58d" };
        }
        if (row.status === 0) {
          return { color: "#ffb800" };
        }
        if (row.status === 2) {
          return { color: "#F7819F" };
        }
        if (row.status === 3) {
          return { color: "#585858" };
        }
      },
  
      //下载照片
      clickDown() {
        const a = document.createElement("a");
        a.href = this.photoImg;
        console.log(a.href);
        let str =
          // this.studentForm.idNumber +
          // "-" +
          this.orderForm.studentName +
          "-" +
          this.orderForm.studentCode +
          "-" +
          this.orderForm.schoolName +
          "-" +
          this.orderForm.schoolId +
          ".jpg";
        a.download = str; // 下载文件的名字
        document.body.appendChild(a);
        a.click();
        //在资源下载完成后 清除 占用的缓存资源
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      },
      queryButton() {
        const data = 4;
        queryPageButton(data).then((resp) => {
          for (const button of resp.data) {
            if (button.code === "search") {
              this.searchButton = true;
            }
            if (button.code === "view") {
              this.viewButton = true;
            }
            if (button.code === "upload") {
              this.uploadButton = true;
            }
            if (button.code === "cencel") {
              this.cencelButton = true;
            }
            if (button.code === "reUpload") {
              this.reUploadButton = true;
            }
            if (button.code === "orderFlowByorderNo") {
              this.orderFlowByorderNo = true;
            }
            if (button.code === "showOrderLogXp") {
              this.showOrderLogXp = true;
            }
          }
        });
      },
      querySchools() {
        getSchoolFiller().then((resp) => {
          this.allSchools = resp.data;
          this.schools = resp.data;
        });
      },
      remoteMethod(query) {
        if (query !== "") {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.schools = this.allSchools.filter((item) => {
              if (typeof item != undefined) {
                return item.schoolFilterName.indexOf(query) > -1;
              }
            });
          }, 200);
        } else {
          this.schools = [];
        }
      },
      /**
       * 查询订单日志
       */
      showOrderLog(taskId) {
        let that = this;
        that.orderLogsLoading = true;
        getLogs(taskId).then((res) => {
          if (res.code == 0) {
            that.orderLogsList = res.data;
            that.showOrderView = true;
          }
          that.orderLogsLoading = false;
        });
      },
      query() {
        this.tableLoading = true;
        queryOrders(this.currentPage, this.pageSize, this.searchData).then(
          (resp) => {
            this.orderList = resp.data.content;
            this.total = resp.data.totalElements;
            this.tableLoading = false;
          }
        );
      },
      handleSearch() {
        this.currentPage = 1;
        this.query();
      },
      // 上传照片
      handleImport(id) {
        if (this.$refs.fileForm != undefined) {
          //重置表单信息
          this.$refs.fileForm.resetFields();
        }
        this.orderId = id;
        this.isReImport = false;
        this.dlgImportVisible = true;
        this.fileList = [];
        this.uploadLoading = false;
  
        this.uploadImgType = 1;
      },
      // 重传照片
      handleReImport(id, resp) {
        if (this.$refs.fileForm != undefined) {
          //重置表单信息
          this.$refs.fileForm.resetFields();
        }
        this.orderId = id;
        this.isReImport = true;
        this.dlgImportVisible = true;
        this.fileList = [];
        this.uploadLoading = false;
        this.uploadImgType = 2;
        this.uploadImg = "../temp/none_img.jpg";
        this.photoImg = "../temp/none_img.jpg?timestamp=" + new Date().getTime();
        this.isPhoto = false;
        this.getPhoto(resp.pathCollectedPhoto);
      },
      // 查看
      handleView(orderId) {
        this.orderForm = {};
        this.isPhoto = false;
        this.photoImg = "../temp/none_img.jpg?timestamp=" + new Date().getTime();
  
        collectOrderInfo(orderId)
          .then((resp) => {
            if (resp.data) {
              this.dialogVisible = true;
              this.orderForm = resp.data;
              if (
                resp.data.pathCollectedPhoto == null ||
                resp.data.pathCollectedPhoto == ""
              ) {
                this.photoImg =
                  "../temp/none_img.jpg?timestamp=" + new Date().getTime();
                this.isPhoto = false;
              } else {
                this.getPhoto(resp.data.pathCollectedPhoto);
              }
              this.dialogType = 2;
            }
          })
          .catch((error) => {});
      },
      //上传学生照片
      submitUpload() {
        this.uploadLoading = true;
        if (this.$refs.upload) {
          this.$refs.upload.clearFiles(); // 清除上传的文件
        }
        this.fileList = [];
        this.$refs.fileForm.validate((valid) => {
          if (valid) {
            let dataPar = this.fileForm;
            let fdParams = new FormData();
            fdParams.append("id", this.orderId);
            fdParams.append("file", dataPar.file.raw);
            fdParams.append("type", dataPar.type);
            if (this.isReImport == false) {
              uploadPhoto(fdParams).then((resp) => {
                if (resp.data.code == 0) {
                  this.dlgImportVisible = false;
                  this.$notify({
                    title: "温馨提示",
                    message: resp.message,
                    type: "success",
                  });
                }
                this.uploadLoading = false;
                this.query();
              });
            } else if (this.isReImport == true) {
              reUploadPhoto(fdParams).then((resp) => {
                if (resp.data.code == 0) {
                  this.dlgImportVisible = false;
                  this.$notify({
                    title: "温馨提示",
                    message: resp.message,
                    type: "success",
                  });
                }
                this.uploadLoading = false;
                this.msgVisible = false;
                this.query();
              });
            }
          }
        });
      },
      getPhoto(fileid) {
        const url =
          //window._config["baseUrl"] +
          "/api/csias/file/download/" +
          fileid +
          "?timestamp=" +
          new Date().getTime();
        axios({
          method: "GET",
          url: url,
          headers: {
            Authorization: "Bearer " + getToken(),
          },
          responseType: "blob",
          timeout: 180000,
        }).then(
          (response) => {
            if (response.status === 200 && response.data) {
              const imgContent = response.data;
              this.photoImg = window.URL.createObjectURL(imgContent);
              this.isPhoto = true;
            }
          },
          (err) => {
            this.$notify.error({
              title: "错误",
              message: "照片加载失败，未查询到照片信息",
            });
          }
        );
      },
  
      handleChange(file, fileList) {
        if (fileList.length >= 2) {
          return;
        }
        if (fileList.length === 1) {
          this.hasFile = true;
        }
        this.uploadImg = file.url;
        this.fileForm.file = file;
      },
  
      handleSizeChange(val) {
        this.pageSize = val;
        this.query();
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.query();
      },
      columnDateFormat(row, column, cellValue, index) {
        const dateValue = row[column.property];
        if (dateValue != null) {
          return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
        }
      },
      formDateFormat(dateValue) {
        if (dateValue != null) {
          return dateFormat("YYYY-mm-dd", new Date(dateValue));
        }
      },
      formDateTimeFormat(dateValue) {
        if (dateValue != null) {
          return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
        }
      },
      // 姓名、手机号脱敏
      execDecrypt(str, mod) {
        if (!str) {
          return str;
        } else if (mod == "ph") {
          return formatPh(str);
        } else if (mod == "name") {
          return formatName(str);
        } else if (mod == "Idcard") {
          return formatIdCard(str);
        }
      },
    },
  };
  </script>
  
  <style>
  .tip {
    padding: 8px 16px;
    background-color: #f9f9f9;
    border-radius: 4px;
    border-left: 5px solid #446cf3;
    margin: 5px 0;
    line-height: 20px;
  }
  
  .rowGray .cell,
  .rowBlank .cell {
    padding: 0 5px;
    border: #e8e8e8 1px solid;
    height: 40px;
    line-height: 40px;
    vertical-align: bottom;
  }
  
  .rowGray .cell {
    background: #f2f2f2;
  }
  
  .divice {
    width: 1200px;
  }
  
  .divice .col-1eft {
    float: left;
    width: 900px;
    height: 160px;
  }
  
  .divice .col-right {
    float: left;
    margin-left: 5px;
    width: 200px;
    height: 150px;
  }
  
  .clear-float {
    clear: both;
  }
  
  .photo {
    width: 120px;
    height: 160px;
    position: relative;
    vertical-align: top;
  }
  .photo-box .item {
    text-align: center;
  }
  .photo-box .item .desc {
    line-height: 30px;
    text-align: center;
  }
  .photo-box .item .photo {
    margin-right: 0;
  }
  </style>
  